<template>
    <div
        class="Datepicker"
        :class="[{ minimal: variant === 'minimal' }, { inline: variant === 'inline' }]"
    >
        <flat-pickr
            ref="calender"
            v-model="date"
            :config="config"
            :class="[{ active: datePickerIsOpen }, { 'is-month-picker': monthPicker }]"
            @onChange="handleDateChange"
            @onOpen="onOpen"
            @onClose="onClose"
        >
        </flat-pickr>

        <div v-if="variant === 'inline'" class="InlineDatePickerTrigger" @click="toggleDatepicker">
            <slot></slot>
        </div>

        <div class="QuickActions" v-if="!monthPicker && variant !== 'inline'">
            <button @click="handleQuickAction('currentYear')">{{ currentYear }}</button>
            <button @click="handleQuickAction('lastMonth')">{{ lastMonth }}</button>
            <button @click="handleQuickAction('currentMonth')">{{ currentMonth }}</button>
        </div>

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" v-if="variant === 'minimal'" v-bind:width="'24'" v-bind:height="'24'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="currentColor" d="M17 3h4a1 1 0 011 1v16a1 1 0 01-1 1H3a1 1 0 01-1-1V4a1 1 0 011-1h4V1h2v2h6V1h2v2zM4 9v10h16V9H4zm2 2h2v2H6v-2zm5 0h2v2h-2v-2zm5 0h2v2h-2v-2z"/></svg>
        <div v-else-if="variant !== 'inline'" class="Wrapper">
            <span
                class="From"
                :class="[{ 'is-set': from.length > 0 }, { 'is-empty': from.length === 0 }]"
                @click="toggleDatepicker"
            >
                {{ from }} <span v-if="to.length > 0 && !monthPicker">- {{ to }}</span>
            </span>
        </div>
    </div>
</template>
<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/plugins//monthSelect/style.css';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import { de } from 'date-fns/locale';
import { German } from 'flatpickr/dist/l10n/de.js';

import {
    subDays,
    format,
    isFuture,
    subYears,
    subMonths,
    startOfMonth,
    endOfMonth,
    startOfYear,
} from 'date-fns';

export default {
    components: {
        flatPickr,
    },
    props: {
        onDateChange: {
            type: Function,
            default: () => {},
        },
        monthPicker: Boolean,
        dayPicker: Boolean,
        startDate: {
            type: Date,
            default: () => subDays(new Date(), 30),
        },
        endDate: {
            type: Date,
        },
        variant: {
            type: String,
            default: 'default',
        },
    },
    data() {
        return {
            date: new Date(),
            from: this.monthPicker
                ? format(new Date(this.startDate), 'LLLL — yyy', { locale: de })
                : format(new Date(this.startDate), 'dd.MM.yyyy', { locale: de }),
            to: format(new Date(), 'dd.MM.yyyy', { locale: de }),
            svgFill: 'var(--color-text-black)',
            datePickerIsOpen: false,
            config: {
                defaultDate: this.startDate,
                maxDate: new Date(),
                dateFormat: this.monthPicker ? 'F — Y' : 'd.m.Y',
                mode: this.dayPicker || this.monthPicker ? 'single' : 'range',
                locale: German,
                onChange: this.handleDateChange,
                onOpen: this.onOpen,
                onClose: this.onClose,
                disableMobile: true,
                plugins: [
                    this.monthPicker
                        ? new monthSelectPlugin({
                              shorthand: false,
                              dateFormat: 'F — Y',
                              altFormat: 'M Y',
                          })
                        : () => ({}),
                ],
            },
        };
    },
    computed: {
        currentMonth() {
            return format(new Date(), 'LLLL', { locale: de });
        },
        lastMonth() {
            return format(subMonths(new Date(), 1), 'LLLL', { locale: de });
        },
        currentYear() {
            return format(new Date(), 'yyyy', { locale: de });
        },
    },
    watch: {
        startDate: function(val) {
            if (!val) return;
            this.from = this.monthPicker
                ? format(new Date(val), 'LLLL — yyy', { locale: de })
                : format(new Date(val), 'dd.MM.yyyy', { locale: de });
        },
        endDate: function(val) {
            if (!val) return;
            this.to = this.monthPicker
                ? format(new Date(val), 'LLLL — yyy', { locale: de })
                : format(new Date(val), 'dd.MM.yyyy', { locale: de });
        },
        date: function(val) {
            if (!val) return;
            let date = val.split('bis');
            this.from = date?.[0]?.trim();
            this.svgFill = '#616C9F';
            if (!date[1]) {
                this.svgFill = 'transparent';
            }
            this.to = (date[1] && date[1].trim()) || '';
        },
    },
    methods: {
        handleQuickAction(action) {
            let from = new Date();
            let to = new Date();

            switch (action) {
                case 'currentMonth':
                    from = startOfMonth(from);
                    to = new Date(); // Current date for ongoing month
                    break;
                case 'currentYear':
                    from = startOfYear(from);
                    to = new Date(); // Current date for the ongoing year
                    break;
                case 'lastMonth':
                    from = startOfMonth(subMonths(new Date(), 1));
                    to = endOfMonth(subMonths(new Date(), 1));
                    break;
                case 'lastYear':
                    from = startOfYear(subYears(new Date(), 1));
                    to = endOfYear(subYears(new Date(), 1));
                    break;
                default:
                    console.warn('Unknown quick action:', action);
                    return;
            }

            // Emit event with the calculated date range
            this.$emit('onChange', { from, to });
        },
        toggleDatepicker() {
            if (this.picker.isOpen) {
                this.picker.close();
            } else {
                this.picker.open();
            }
        },
        handleDateChange(dateArray) {
            const range = { from: dateArray[0], to: dateArray[1] };
            if (this.monthPicker) {
                const month = new Date(range.from).getMonth();
                const year = new Date(range.from).getFullYear();
                const lastDayOfMonth = new Date(year, month + 1, 0);
                const rangeTo = isFuture(lastDayOfMonth) ? new Date() : lastDayOfMonth;
                range.to = rangeTo;
            } else if (this.dayPicker) {
                range.to = range.from;
            }

            if (!range.from || !range.to) return;

            this.onDateChange(range);
            this.$emit('onChange', range);
        },
        onOpen() {
            this.datePickerIsOpen = true;
        },
        onClose() {
            this.datePickerIsOpen = false;
        },
    },
    mounted() {
        this.calendar = this.$refs.calender.$el;
        this.picker = flatpickr(this.calendar, this.config);
        this.picker.clear();
    },
};
</script>
<style lang="scss">
.Datepicker {
    position: relative;
    display: flex;
    align-items: center;

    &.minimal {
        .From.is-set {
            width: 100%;
            padding: 0;
            margin: 0;
            border: none;
            background: transparent;
        }
    }

    &.inline {
        cursor: pointer;
    }

    input {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
    }

    .Wrapper {
        display: flex;
        align-items: center;

        > * {
            margin-right: 8px;
        }
    }

    .From,
    .To {
        background-color: transparent;
        outline: none;
        border: solid 1px var(--color-text-inactive);
        border-radius: 5px;
        font-family: font(regular), sans-serif;
        font-weight: 700;
        color: var(--color-text-black);
        font-size: inherit;
        padding: 0;
        user-select: none;
        background: rgba(0, 0, 0, 0.02);
        cursor: pointer;
        transition: background 0.3s ease-out;

        &:hover {
            background: rgba(0, 0, 0, 0.1);
        }

        &.is-empty {
            border-color: transparent;
        }

        &.is-set {
            padding: 8px 12px;
        }
    }

    .QuickActions {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0px;
        top: unset;
        transform: translateY(70%);
        left: 0;
        right: 0;

        button {
            background: var(--color-bg);
            border: 1px solid var(--color-text-inactive);
            border-radius: 5px;
            padding: 2px 4px;
            font-size: 10px !important;
            margin: 0 2px;
            font-family: font(regular), sans-serif;
            color: var(--color-text-black);
            cursor: pointer;
            transition: background 0.3s ease-out;
            background: var(--color-bg);

            * {
                font-size: 12px !important;
            }
            &:hover {
                filter: brightness(0.9);
            }
        }
    }
}

.flatpickr-input {
    pointer-events: none;
}

.flatpickr-calendar {
    border-radius: 0;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--color-white);

    transition: transform 0.3s ease;
    transform-origin: top;
    display: inline-block;
    visibility: hidden;
    opacity: 0;
    position: fixed;
}

.flatpickr-day.endRange.endRange,
.flatpickr-day.endRange.startRange,
.flatpickr-day.selected.endRange,
.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.startRange.startRange {
    border-radius: 0;
    border-color: var(--color-blue-dark);
    border: none;
    background: color(blue-dark);
}

.flatpickr-monthSelect-month {
    color: var(--color-text-black);
    font-family: inherit;

    &:hover {
        background: var(--color-bg);
    }
    &.selected {
        background: var(--color-blue-dark);
    }
}

.flatpickr-day.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day.nextMonthDay:focus,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.today.inRange,
.flatpickr-day:focus,
.flatpickr-day:hover {
    border-radius: 0;
    border: none;
}

.flatpickr-monthSelect-theme-light {
    .numInputWrapper {
        pointer-events: none;
    }
}

.flatpickr-day.endRange.startRange.endRange,
.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.today,
.flatpickr-day.today:focus,
.flatpickr-day.today:hover {
    border-color: var(--color-blue-dark);
    border-radius: 0;
}

.flatpickr-calendar {
    .flatpickr-monthDropdown-months,
    .numInputWrapper,
    .flatpickr-next-month,
    .flatpickr-prev-month {
        color: var(--color-text-black);
        fill: var(--color-text-black);
    }
    span.flatpickr-weekday {
        color: var(--color-text-black);
    }
    span.flatpickr-day {
        color: var(--color-text-blue);
    }
}

.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)) {
    box-shadow: -10px 0 0 color(blue-light);
}

.flatpickr-months {
    margin: 10px 0 20px;
    align-items: center;
    justify-content: center;
    .flatpickr-month {
        margin: 0;
    }
}

.flatpickr-months .flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month {
    margin: 5px 0;
}
</style>
