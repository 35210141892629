var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Datepicker",
    class: [{
      minimal: _vm.variant === 'minimal'
    }, {
      inline: _vm.variant === 'inline'
    }]
  }, [_c('flat-pickr', {
    ref: "calender",
    class: [{
      active: _vm.datePickerIsOpen
    }, {
      'is-month-picker': _vm.monthPicker
    }],
    attrs: {
      "config": _vm.config
    },
    on: {
      "onChange": _vm.handleDateChange,
      "onOpen": _vm.onOpen,
      "onClose": _vm.onClose
    },
    model: {
      value: _vm.date,
      callback: function callback($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  }), _vm.variant === 'inline' ? _c('div', {
    staticClass: "InlineDatePickerTrigger",
    on: {
      "click": _vm.toggleDatepicker
    }
  }, [_vm._t("default")], 2) : _vm._e(), !_vm.monthPicker && _vm.variant !== 'inline' ? _c('div', {
    staticClass: "QuickActions"
  }, [_c('button', {
    on: {
      "click": function click($event) {
        return _vm.handleQuickAction('currentYear');
      }
    }
  }, [_vm._v(_vm._s(_vm.currentYear))]), _c('button', {
    on: {
      "click": function click($event) {
        return _vm.handleQuickAction('lastMonth');
      }
    }
  }, [_vm._v(_vm._s(_vm.lastMonth))]), _c('button', {
    on: {
      "click": function click($event) {
        return _vm.handleQuickAction('currentMonth');
      }
    }
  }, [_vm._v(_vm._s(_vm.currentMonth))])]) : _vm._e(), _vm.variant === 'minimal' ? _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "width": '24',
      "height": '24',
      "svg-inline": '',
      "role": 'presentation',
      "focusable": 'false',
      "tabindex": '-1'
    }
  }, [_c('path', {
    attrs: {
      "fill": "currentColor",
      "d": "M17 3h4a1 1 0 011 1v16a1 1 0 01-1 1H3a1 1 0 01-1-1V4a1 1 0 011-1h4V1h2v2h6V1h2v2zM4 9v10h16V9H4zm2 2h2v2H6v-2zm5 0h2v2h-2v-2zm5 0h2v2h-2v-2z"
    }
  })]) : _vm.variant !== 'inline' ? _c('div', {
    staticClass: "Wrapper"
  }, [_c('span', {
    staticClass: "From",
    class: [{
      'is-set': _vm.from.length > 0
    }, {
      'is-empty': _vm.from.length === 0
    }],
    on: {
      "click": _vm.toggleDatepicker
    }
  }, [_vm._v(" " + _vm._s(_vm.from) + " "), _vm.to.length > 0 && !_vm.monthPicker ? _c('span', [_vm._v("- " + _vm._s(_vm.to))]) : _vm._e()])]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }